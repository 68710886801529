import {Compiler, Component, Directive, Input, NgModule, OnInit, ViewContainerRef} from '@angular/core';
import {EzPlusModule} from 'ng-elevatezoom-plus';
import {CommonModule} from '@angular/common';
import {NgMasonryGridPlusModule} from 'ng-masonry-grid-plus';

@Directive({
  selector: '[lingxin-dynamic-html]'
})
export class DynamicHtmlDirective implements OnInit {
  @Input('lingxin-dynamic-html') dynamicHtml: string;

  constructor(private viewContainerRef: ViewContainerRef, private compiler: Compiler) {
  }

  ngOnInit(): void {
    const template = this.dynamicHtml;

    @Component({
      selector: 'lingxin-dynamic-html',
      template
    })
    class TemplateComponent {
    }

    @NgModule({
      imports: [
        CommonModule,
        EzPlusModule,
        NgMasonryGridPlusModule
      ],
      declarations: [
        TemplateComponent
      ]
    })
    class TemplateModule {
    }

    const module = this.compiler.compileModuleAndAllComponentsSync(TemplateModule);
    const factory = module.componentFactories.find(componentFactory => componentFactory.componentType === TemplateComponent);
    const componentRef = this.viewContainerRef.createComponent(factory);
    // Object.assign(componentRef.instance, {mundo: (window as any).mundo});
  }
}
