import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/internal/operators';
import {Router} from '@angular/router';
// import {TokenService} from '../services/token.service';
import {ModalContentComponent} from '../../components/modal-content/modal-content.component';
// import {ZoeLayoutSettingsService} from '@wcs/zoe-layout';
import {NzModalService} from 'ng-zorro-antd/modal';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

  constructor(
    // private tokenService: TokenService,
    private modal: NzModalService,
    private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const token = this.tokenService.getToken();
    let request;
    // if (token != null) {
    //   request = req.clone({
    //     headers: req.headers.set('Token', token).set('Source', '3').set('Accept', 'application/json')
    //   });
    // } else {
    request = req.clone({
      headers: req.headers.set('Accept', 'application/json')
    });

    // }
    return next.handle(request)
      .pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 500) {
              if (err.error.code === 1013) {
                // this.tokenService.removeToken();
                // this.settingsService.setUser(null);
                this.modal.error({
                  nzTitle: '提示',
                  nzContent: '您当前的会话已超时，请重新登录',
                  nzOkText: '去登录',
                  nzOnOk: () => new Promise((resolve, reject) => {
                    this.router.navigate(['login']);
                    resolve();
                  }).catch(() => console.log('Oops errors!'))
                });
              } else {
                // this.tokenService.removeToken();
                // this.settingsService.setUser(null);
                // this.modal.error({
                //   nzTitle: '提示',
                //   nzContent: '您当前的会话已超时，请重新登录',
                //   nzOkText: '去登录',
                //   nzOnOk: () => new Promise((resolve, reject) => {
                //     this.router.navigate(['login']);
                //     resolve();
                //   }).catch(() => console.log('Oops errors!'))
                // });
              }
              this.modal.info(err.error.message);
            }
          }
        })
      );
  }

}
