import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {RouterModule, Routes, UrlSerializer} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {KayleCreatorModule} from '@wcs/kayle-creator';
import {ComponentModule} from '../components/components.module';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzTreeSelectModule} from 'ng-zorro-antd/tree-select';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzInputNumberModule} from 'ng-zorro-antd/input-number';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NZ_ICONS, NzIconModule} from 'ng-zorro-antd/icon';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {ModalContentComponent} from '../components/modal-content/modal-content.component';
import {NZ_I18N, zh_CN} from 'ng-zorro-antd/i18n';
import {DefaultInterceptor} from '../core/token/default.interceptor';
import zh from '@angular/common/locales/zh';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {IconDefinition} from '@ant-design/icons-angular';
// import {LayoutDefaultComponent} from './layout-default/layout-default.component';
import {HomeComponent} from './home/home.component';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzMenuModule} from 'ng-zorro-antd/menu';
// @ts-ignore
import {KayleCreatorModule} from '@wcs/kayle-creator';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzStatisticModule} from 'ng-zorro-antd/statistic';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import {DynamicHtmlDirective} from '../directives/dynamic-html.directive';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
// import {EmitService} from '../core/services/emit.servic';
import {CustomUrlSerializer} from './CustomUrlSerializer';
// import {AccountDetailComponent} from './account-detail/account-detail.component';
// import {CustomerSettleComponent} from './customer-settle/customer-settle.component';


const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

registerLocaleData(zh);

const appRoute: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      // {path: '', component: HomeComponent},
      {path: '', component: HomeComponent},
      // {path: 'template', component: TemplateComponent},
      // {path: 'template-survey', component: TemplateSurveyComponent},
      // {path: 'task', component: TaskComponent},
      // {path: 'task-edit', component: TaskEditComponent},
      // {path: 'task-modify', component: TaskModifyComponent},
      // {path: 'task-setting', component: TaskSettingComponent},
      // {path: 'task-approval', component: TaskApprovalComponent},
      // {path: 'task-detail', component: TaskDetailComponent},
      // {path: 'user', component: UserComponent},
      // {path: 'message', component: MessageComponent},
      // {path: 'account', component: AccountComponent},
      // {path: 'display-answer', component: DisplayAnswerComponent},
      // {path: 'withdraw', component: WithdrawComponent},
      // {path: 'charge', component: ChargeComponent},
      // {path: 'management', component: ManagementComponent},
      // {path: 'manage-task', component: ManageTaskComponent},
      // {path: 'manage-verified', component: ManageVerifiedComponent},
      // {path: 'manage-withdraw', component: ManageWithdrawComponent},
      // {path: 'verified-info/:id', component: VerifiedInfoComponent},
      // {path: 'task-info/:id', component: TaskInfoComponent},
      // {path: 'manualWithdraw', component: ReportComponent},
      // {path: 'report-detail/:id', component: ReportDetailComponent},
      // {path: 'enterpriseList', component: ArchitectureComponent},
      // {path: 'userMgr', component: CustomerComponent},
      // {path: 'worker', component: WorkerComponent},
      // {path: 'worker-laba', component: WorkerLabaComponent},
      // {path: 'autoWithdraw', component: BatchPaymentComponent},  // batch-payment
      // {path: 'batch-payment-detail/:id', component: BatchPaymentDetailComponent},
      // {path: 'workerIdentify', component: AuditComponent},
      // {path: 'channel', component: ChannelComponent},
      // {path: 'channel-detail/:id', component: ChannelDetailComponent},
      // {path: 'enterpriseCodeMgr', component: EnterpriseCodeMgrComponent},
      // {path: 'account-laba', component: AccountLabaComponent},
      // {path: 'report-list', component: ReportListComponent},
      // {path: 'report-list-detail/:id', component: ReportListDetailComponent},
      // {path: 'account-laba-detail', component: AccountLabaDetailComponent},
      // {path: 'account-detail', component: AccountDetailComponent},
      // {path: 'activity', component: ActivityComponent},
      // {path: 'labelMgr', component: LabelComponent},
      // {path: 'orgMgr', component: OrganizationComponent},
      // {path: 'customerService', component: CustomerServiceComponent},
      // {path: 'workFlowList', component: WorkFlowListComponent},
      // {path: 'todoList', component: TodoListComponent},
      // {path: 'allItems', component: AllItemsComponent},
      // {path: 'roleMgr', component: RoleComponent},
      // {path: 'permissionMgr', component: RoleComponent},
      // // {path: 'workflow-payment-detail/:id', component: WorkflowPaymentDetailComponent},
      // {path: 'workflow-payment-detail', component: WorkflowPaymentDetailComponent},
      // {path: 'customerSettle', component: CustomerSettleComponent},


    ]
  },
  // {path: 'login', component: LoginComponent},
  // {path: 'register', component: RegisterComponent},
  // {path: 'logout', component: LogoutComponent},
];

// 组织架构
@NgModule({
  declarations: [
    DynamicHtmlDirective,
    // LayoutDefaultComponent,
    HomeComponent,
    // LoginComponent,
    // LogoutComponent,
    // RegisterComponent,
    // TemplateComponent,
    // TaskComponent,
    // UserComponent,
    // AccountComponent,
    // TaskApprovalComponent,
    // TaskEditComponent,
    // TaskSettingComponent,
    // MessageComponent,
    // DisplayAnswerComponent,
    // ChargeComponent,
    // WithdrawComponent,
    // TaskDetailComponent,
    // TemplateSurveyComponent,
    // ManagementComponent,
    // ManageTaskComponent,
    // ManageVerifiedComponent,
    // ManageWithdrawComponent,
    // VerifiedInfoComponent,
    // TaskInfoComponent,
    // ReportComponent,
    // TaskModifyComponent,
    // ArchitectureComponent,
    // WorkerComponent,
    // WorkerLabaComponent,
    // CustomerComponent,
    // AuditComponent,
    // BatchPaymentComponent,
    // ReportDetailComponent,
    // BatchPaymentDetailComponent,
    // ChannelComponent,
    // ChannelDetailComponent,
    // EnterpriseCodeMgrComponent,
    // AccountLabaComponent,
    // ReportListComponent,
    // AccountLabaDetailComponent,
    // ActivityComponent,
    // LabelComponent,
    // ReportListDetailComponent,
    // OrganizationComponent,
    // RoleComponent,
    // CustomerServiceComponent,
    // WorkFlowListComponent,
    // TodoListComponent,
    // AllItemsComponent,

    // DynamicFormComponent,
    // DynamicFieldComponent,
    // InputFieldComponent,
    // TextareaFieldComponent,
    // SelectFieldComponent,
    // AFieldComponent,
    // SpanFieldComponent,
    // UploadFieldComponent,
    // WorkflowPaymentDetailComponent,
    // AccountDetailComponent,
    // CustomerSettleComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoute),
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    ComponentModule,
    NzTableModule,
    NzDatePickerModule,
    NzGridModule,
    NzLayoutModule,
    NzButtonModule,
    NzTreeSelectModule,
    NzTreeModule,
    NzInputModule,
    NzInputNumberModule,
    NzTabsModule,
    NzRadioModule,
    NzTableModule,
    NzDatePickerModule,
    NzModalModule,
    NzSelectModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzSpinModule,
    NzToolTipModule,
    NzIconModule,
    NzPopoverModule,
    NzGridModule,
    NzTabsModule,
    NzCardModule,
    NzMenuModule,
    NzDividerModule,
    NzListModule,
    NzPaginationModule,
    NzUploadModule,
    NzStatisticModule,
    NzBadgeModule,


  ],
  entryComponents: [ModalContentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule],
  providers: [
    {provide: NZ_ICONS, useValue: icons},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: NZ_I18N, useValue: zh_CN},
    {provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true},
    {provide: UrlSerializer, useClass: CustomUrlSerializer},
    NzMessageService,
    // EmitService
  ],
})
export class RoutesModule {
}
