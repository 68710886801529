import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  constructor(
  ) {
    // this.layoutSettings.appName = '领鑫';
    // this.layoutSettings.navBar = 'disabled';

  }


}
