import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzModalRef} from 'ng-zorro-antd/modal';

@Component({
  templateUrl: './modal-content.component.html',
})
export class ModalContentComponent implements OnInit {

  constructor(private router: Router, private modal: NzModalRef) {
  }

  ngOnInit() {
  }

  destroyModal() {
    this.router.navigate(['login']);
    this.modal.destroy();
  }

}
