import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UtilService} from './util.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BarcodeService {

  private search_barcodeServiceUrl = environment.serviceUrl + '/api/search_barcode';


  constructor(private httpClient: HttpClient,
              private utilService: UtilService) {
  }

  async search_barcode(barcode: any ) {
    return await this.httpClient.get(this.search_barcodeServiceUrl, {
      params: {
        barcode: barcode,
      },
      // withCredentials: true
    }).toPromise();
  }
}
