import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }


  formatDate(time) {
    const date = new Date(time);
    const year = date.getFullYear(),
      month = date.getMonth() + 1, // 月份是从0开始的
      day = date.getDate();
    // hour = date.getHours(),
    // min = date.getMinutes(),
    // sec = date.getSeconds();
    return year + '-' +
      (month < 10 ? '0' + month : month) + '-' +
      (day < 10 ? '0' + day : day) + ' 00:00:00';
  }

  formatDateAndSeconds(time) {
    const date = new Date(time);
    const year = date.getFullYear(),
      month = date.getMonth() + 1, // 月份是从0开始的
      day = date.getDate(),
      hour = date.getHours(),
      min = date.getMinutes(),
      sec = date.getSeconds();
    return year + '-' +
      (month < 10 ? '0' + month : month) + '-' +
      (day < 10 ? '0' + day : day) + ' ' + (hour < 10 ? '0' + hour : hour)
      + ':' + (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec);
  }

  LTrim(str) {
    return str.replace(/(^\s*)/g, '');
  }

  RTrim(str) {
    return str.replace(/(\s*$)/g, '');
  }

  getChildren(children: any) {
    const childrenArr = [];
    for (let i = 0; i < children.length; i++) {
      const sub = children[i];
      const childrenParent: any = {
        title: sub.name,
        key: sub.id,
        isLeaf: sub.leaf,
        customerId: sub.customerId,
      };
      childrenArr.push(childrenParent);
      if (sub.children) {
        childrenParent.children = this.getChildren(sub.children);
      }
    }
    return childrenArr;
  }

  getChildrenChecked(children: any) {
    const childrenArr = [];
    for (let i = 0; i < children.length; i++) {
      const sub = children[i];

      if (sub._isLeaf) {

      }
    }
    return childrenArr;
  }

  returnChild(children: any, result: any) {
    for (let i = 0; i < children.length; i++) {
      const sub = children[i];
      if (sub.isLeaf) {
        if (sub.checked) {
          result.push(sub.key);
        }
      } else {
        this.returnChild(sub.children, result);
      }
    }
    return result;

  }

}
