import {Component, OnInit} from '@angular/core';
import {BarcodeService} from '../../core/services/barcode.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  barcode: any;
  gridStyle = {
    width: '20%',
    textAlign: 'center'
  };

  barcodeArr: any;
  barcodeObj: any;
  barcodeImage: string;

  constructor(private barcodeService: BarcodeService,
              private nzMessageService: NzMessageService,
  ) {
  }

  ngOnInit() {
    this.barcodeArr = [];
    // this.barcode = '6927854126141';
  }

  async search() {
    this.barcodeArr = [];
    this.barcodeObj = null;
    if (!this.barcode) {
      this.nzMessageService.info('请输入条码');
      return;
    }
    const data: any = await this.barcodeService.search_barcode(this.barcode);
    if (data) {
      this.barcodeObj = data;
      if (data.images) {
        if (data.images.barcode) {
          this.barcodeImage = environment.fileServerUrl + data.images.barcode;
        }
        const arr = Object.keys(data.images).sort();
        if (arr) {
          for (let i = 0; i < arr.length; i++) {
            const key = arr[i];
            this.barcodeArr.push({
              'name': key,
              value: data.images[key],
              type: typeof data.images[key]
            });
          }
        }
      }
    } else {
      // this.nzMessageService.info('暂无数据');
    }

  }

  getImagePath(path: string) {
    return environment.fileServerUrl + path;
  }

}
