import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSerializer} from '@angular/router';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {IconDefinition} from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';
import {DefaultInterceptor} from './core/token/default.interceptor';
import {ComponentModule} from './components/components.module';
import {NZ_I18N, zh_CN} from 'ng-zorro-antd/i18n';
import {RoutesModule} from './routes/routes.module';
// @ts-ignore
// import {KayleCreatorModule} from '@wcs/kayle-creator';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,


  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    RoutesModule,
    ComponentModule,
    // KayleCreatorModule,
  ],
  providers: [
    {provide: NZ_I18N, useValue: zh_CN},
    {provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true}

  ],


  bootstrap: [AppComponent]

})
export class AppModule {
}
