import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalContentComponent} from './modal-content/modal-content.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    ModalContentComponent,



  ],
  exports: [ModalContentComponent ]
})
export class ComponentModule {
}
